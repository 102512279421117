.calculator {
    width: 17rem;
    height: 24rem;
    background-color: lightcyan;
    display: grid;
    padding: 1%;
    position: absolute;
    /* top: 0;
    bottom: 0;*/
    left: 0;
    right: 0;
    margin: auto; 
    border: ridge;
  }
  
  .keys-numbers {
    grid-area: keys-numbers;
    direction: rtl;
  }
  
  .keys-numbers .key-zero {
    width: 9.1rem;
    text-align: left;
    padding-left: 15%;
  }
  .keys-operators {
    grid-area: keys-operators;
  }
  .keys-function {
    grid-area: keys-function;
  }
  .calculator-keypad {
    width: 17rem;
    height: 20rem;
    padding-top: 0.1rem;
    background-color: lightblue;
    display: grid;
    grid-template-columns: repeat(4, 4rem);
    grid-template-rows: repeat(5, 4rem);
    grid-template-areas: "keys-function keys-function keys-function keys-operators" "keys-numbers keys-numbers keys-numbers keys-operators" "keys-numbers keys-numbers keys-numbers keys-operators" "keys-numbers keys-numbers keys-numbers keys-operators" "keys-numbers keys-numbers keys-numbers keys-operators";
  }
  .calculator-input {
    border-color: black;
    border: ridge;
    text-align: right;
    padding-right: 10%;
    font-weight: bold;
    font-size: xx-large;
  }
  
  .result {
    min-height: 3rem;
    color: #666666;
  }