
.App-login{
    background-color: #f0f0f0;
}
.login-logo{
    
    padding-top: 160px;
}
.all-heigth {
    height: 100vh;
}
.pass-forget{
    text-align: center;
}